<template lang="pug">
	.container
		.card
			.card-body.m-1
				h3 My Bus Sewa Tickets
				b-overlay(:show="loading" )
					b-table(:fields="fields" :items="tickets" striped responsive small @row-clicked="showTicketDetail" clickable)
						template(v-slot:cell(Created)="data")
							div {{moment(data.value).calendar()}}
				b-modal(v-model='showDetail' hide-footer hide-header centered)
					h5 Ticket Details
					div(v-if='bookingDetails')
						.row.px-4(v-for="bookingDetail, key in bookingDetails" :key="key")
							.col-4.text-capitalize {{key}}: 
							.col.font-weight-bold {{bookingDetail}}
</template>

<script>
import moment from "moment";
import axios from "@/axios"
export default {
  data() {
    return {
      loading: false,
      fields: [
        {
          key: "Created",
          label: "Date"
        },
        {
          key: "referenceNumber"
        },
        {
          key: "bussewa.details.ticketSrlNo",
          label: "Ticket Number"
        },
        {
          key: "status"
        },
        {
          key: "amount"
        }
      ],
      tickets: [],
      showDetail: false,
      bookingDetails: null
    };
  },
  async mounted() {
    const res = await axios.get("services/bussewa/myticketlist");

    if (res.data && res.data.data) {
      this.tickets = res.data.data;
    }
  },
  methods: {
    moment,
    async showTicketDetail(item) {
      this.loading = true;
      const ticketQuery = await axios.get(
        "services/bussewa/myticketlist/details",
        {
          params: {
            id: item._id
          }
        }
      );

      if (
        ticketQuery.data &&
        ticketQuery.data.data.bussewa &&
        ticketQuery.data.data.bussewa.details
      ) {
        this.bookingDetails = ticketQuery.data.data.bussewa.details;
        this.showDetail = true;
      }
      this.loading = false;
    }
  }
};
</script>